import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  // contact('#contact-form')
  rebox('.reference-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  smoothScroll('a[href^="#"]', {offset: 250})
})

$(document).ready(function () {
    $(document).scroll(function () {
      var scroll = $(this).scrollTop()
      if (scroll > 0) {
        $('.header').addClass('fixed');
      } else {
        $('.header').removeClass('fixed');
      }
    })
  })

$(document).ready(function () {
    $(".normal ul").parent().addClass("third-level");
})

$(document).ready(function () {
    $(".current ul, .active ul").parent().addClass("third-level");

    if ((location.pathname.indexOf('kontakt') != -1) ||
        (location.pathname.indexOf('contact') != -1)) {
      $(".widget--wrapper").hide();
  }
})

$(document).ready(function () {
    if ( $(".extra-nav--wrapper div").length >= 8) {
        $('.extra-nav-content').last().addClass('borderTop');
      }
})

$(document).ready(function(){
  $(".read-more").on('click', function(){
    $(this).prev().toggle();
    if ($(this).text()=='Mehr Referenzen ansehen') {
      $(this).text('Weniger Referenzen ansehen');
    }
    else {
      $(this).text('Mehr Referenzen ansehen');
    }
  });
});

$(window).scroll(function() {
    if ($(this).scrollTop() >= 600) {
        $('.back-to-top').fadeIn(300).addClass('visible');
    } else {
        $('.back-to-top').fadeOut(300).removeClass('visible');
    };

    if ($(this).scrollTop() <= 2000) {
      $('.widget--wrapper').fadeIn(300).addClass('visible');
    } else {
        $('.widget--wrapper').fadeOut(300).removeClass('visible');
    }
});


$('.back-to-top').click(function() {
    $('body,html').animate({
        scrollTop : 0
    }, 300);
});
